<template>
  <div class="diagrambox">
    <div class="diagrambox-topbox">
      <el-button type="primary" icon="el-icon-arrow-left" @click="goback()">返回</el-button>
      <TitleBox :titlename="'设备报警信息'" />

    </div>
    <div class="diagrambox-center">
      <div class="topbpx">
        <p>报警时间:</p>
        <el-date-picker v-model="value1" type="date" placeholder="选择日期">
        </el-date-picker>
        <p>报警类型:</p>
        <el-select v-model="value" placeholder="请选择" :popper-append-to-body="false" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <p>报警状态:</p>
        <el-select v-model="value" placeholder="请选择" :popper-append-to-body="false" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <p>报警区域:</p>
        <el-select v-model="value" placeholder="请选择" :popper-append-to-body="false" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search">查询</el-button>
        <el-button type="primary" icon="el-icon-refresh">重置</el-button>

      </div>
      <div class="tabbox">
        <el-table :data="tableData" style="width: 100%;color:#BFDAFD"
          :header-cell-style="{background:'#4882C0', color:'#fff'}"
          :row-class-name="tableRowClassName" size="mini">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="id" label="序号">
          </el-table-column>
          <el-table-column prop="date" label="报警日期">
          </el-table-column>
          <el-table-column prop="address" label="报警类型">
          </el-table-column>
          <el-table-column prop="name" label="报警人">
          </el-table-column>
          <el-table-column prop="region" label="地区">
          </el-table-column>
          <el-table-column prop="state" label="报警状态">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)" type="primary" size="small">查看</el-button>
              <el-button @click="workorder(scope.row)" type="primary" size="small">转工单</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="paging">
        <el-button icon="el-icon-delete" type="danger" size="small">删除</el-button>
        <!-- <el-pagination background @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10]"
          :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination> -->
      </div>
    </div>
    <Workorder :class="workordershow ? 'fadeIn' : ''" v-if="workordershow" @cancel="cancel" />
  </div>
</template>

<script>
// import api from '../visualization/api'
import TitleBox from '../visualization/components/title/index.vue'
import Workorder from '../dialog/workorder.vue'

export default {
  props: {},
  components: {
    TitleBox,
    Workorder

  },
  data () {
    return {
      value: '',
      value1: '',
      tableData: [{
        id: 1,
        region: '大湾区',
        state: '未处理',
        date: '2016-05-02',
        name: '王小虎',
        address: '设备老化'
      }, {
        id: 2,
        region: '大湾区',
        state: '未处理',
        date: '2016-05-04',
        name: '王小虎',
        address: '设备老化'
      }, {
        id: 3,
        region: '大湾区',
        state: '未处理',
        date: '2016-05-01',
        name: '王小虎',
        address: '设备老化'
      }, {
        id: 4,
        region: '大湾区',
        state: '未处理',
        date: '2016-05-03',
        name: '王小虎',
        address: '设备老化'
      }, {
        id: 5,
        region: '大湾区',
        state: '未处理',
        date: '2016-05-03',
        name: '王小虎',
        address: '设备老化'
      }, {
        id: 6,
        region: '大湾区',
        state: '未处理',
        date: '2016-05-03',
        name: '王小虎',
        address: '设备老化'
      }, {
        id: 7,
        region: '大湾区',
        state: '未处理',
        date: '2016-05-03',
        name: '王小虎',
        address: '设备老化'
      }, {
        id: 8,
        region: '大湾区',
        state: '未处理',
        date: '2016-05-03',
        name: '王小虎',
        address: '设备老化'
      }, {
        id: 9,
        region: '大湾区',
        state: '未处理',
        date: '2016-05-03',
        name: '王小虎',
        address: '设备老化'
      }, {
        id: 10,
        region: '大湾区',
        state: '未处理',
        date: '2016-05-03',
        name: '王小虎',
        address: '设备老化'
      }],
      options: [],
      currentPage: '1',
      pageSize: 10, // 每页条数
      total: 0, // 总条数
      workordershow: false
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {

    cancel () {
      this.workordershow = false
    },
    handleClick (row) {
    },
    workorder (row) {
      this.workordershow = true
    },
    goback () {
      this.$router.go(-1)
    },
    swArea (index) {
      this.isAction = index
    },
    //  分页
    handleSizeChange (val) {
      this.pageSize = val
    },
    //  切换分页
    handleCurrentChange (val) {
      this.currentPage = val
    },
    tableRowClassName ({ row, rowIndex }) {
      return 'success-row'
    }
  },
  created () {
    //  console.log('...........')
    // console.log(this.settokens, 'ids')
    // this.$store.commit('zsqy/setfootershow', false)
  },
  destroyed () {
  }

}
</script>
<style>
.el-table .success-row {
  background: transparent !important;
}
/*最外层透明*/
.el-table,
.el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
.el-table th,
.el-table tr,
.el-table td {
  background-color: transparent;
}
</style>
<style>
.el-table .warning-row {
  background: rgba(79, 119, 163, 0.4);
}

.el-table .success-row {
  background: transparent;
}
/*最外层透明*/
.el-table,
.el-table__expanded-cell {
  background-color: transparent;
}
/*  表格内背景颜色  */
.el-table th,
.el-table tr,
.el-table td {
  background-color: transparent;
}
</style>
<style  lang="less"  scoped>
.diagrambox {
  width: 100%;
  height: 100%;
  background: url('../../assets/homeimg/bg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  .echarbiongt {
    width: 100%;
    height: 100%;
  }
  &-topbox {
    width: 96%;
    height: 40px;
    margin: 100px 0 1% 2%;
    //  margin-left:  2%;
    //  margin-bottom:  1%;
    display: flex;
    align-items: center;
    /deep/.el-input__inner {
      background-color: #5376a1;
      border: none;
      color: #bfdafd;
      font-size: 12px;
      border-radius: 0px;
    }
    p {
      margin: 0 15px;
      color: #fff;
      font-size: 16px;
      font-family: SourceHanSansCN;
      font-weight: 400;
    }
    .el-button {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  &-center {
    width: 96%;
    height: 75%;
    background: rgba(98, 145, 200, 0.1);
    margin-left: 2%;
    border: 2px solid #9fd6eb;
    overflow: hidden;
    position: relative;
    .topbpx {
      width: 96%;
      height: 70px;
      display: flex;
      align-items: center;
      margin-left: 2%;
      border-bottom: 1px solid rgba(98, 145, 200, 0.6);
      /deep/.el-input__inner {
        background-color: #5376a1;
        border: none;
        color: #bfdafd;
        font-size: 12px;
        border-radius: 0px;
      }
      p {
        margin: 0 15px;
        color: #fff;
        font-size: 16px;
        font-family: SourceHanSansCN;
        font-weight: 400;
      }
      .el-button {
        margin-left: 20px;
      }
    }
    .tabbox {
      width: 96%;
      height: 71%;
      margin: 10px 0 0 2%;
      border-bottom: 1px solid rgba(98, 145, 200, 0.6);
      /deep/.el-table--border:after,
      .el-table--group:after,
      .el-table:before {
        background-color: transparent;
      } //el table表单最下面下边框颜色
      /deep/.el-table td,
      .el-table th.is-leaf {
        border-bottom: 1px solid transparent !important;
        text-align: center;
      } //el table表单下边框颜色
      /deep/ .el-table tbody tr:hover > td {
        background-color: rgba(79, 119, 163, 0.4);
      } //鼠标移入表单背景颜色
      /deep/.el-table th.is-leaf {
        border-bottom: 1px solid #133e47;
        color: #fff;
        text-align: center;
      } //表头下边框颜色
    }
    .paging {
      width: 100%;
      color: #fff !important;
      display: flex;
      justify-content: space-between;
      padding: 0 2%;
      margin-top: 20px;
      /deep/.el-input__inner {
        background: transparent;
        border: 1px solid #abdaff;
        color: #fff;
      } //input背景颜色
      /deep/.btn-prev {
        background: transparent;
        color: #fff;
      } //左箭头按钮背景
      /deep/.btn-next {
        background: transparent;
        color: #fff;
      } //右箭头按钮背景
      /deep/.number {
        background: transparent;
        color: #fff;
      } //未被选中的页码背景
      /deep/.active {
        background: #0090ff !important;
        color: #fff;
      } //被选中的页码背景
      /deep/.el-pagination.is-background .el-pager li {
        background: transparent;
        color: #fff;
      } //被折叠的页码背景
      /deep/.el-pagination__total {
        color: #fff;
      } //总条数字体颜色
      /deep/.el-pagination__jump {
        color: #fff;
      } //前往第几页数字体颜色
    }
  }
  /*弹层动画（从上往下）*/
  .fadeIn {
    -webkit-animation: fadeInDown 0.3s;
    animation: fadeInDown 0.3s;
  }
  @keyframes fadeInDown {
    0% {
      -webkit-transform: translate3d(0, -20%, 0);
      -webkit-transform: translate3d(0, -20%, 0);
      transform: translate3d(0, -20%, 0);
      transform: translate3d(0, -20%, 0);
      opacity: 0;
    }
    100% {
      -webkit-transform: none;
      transform: none;
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeInDown {
    0% {
      -webkit-transform: translate3d(0, -20%, 0);
      opacity: 0;
    }
    100% {
      -webkit-transform: none;
      opacity: 1;
    }
  }
}
</style>

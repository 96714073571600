<template>
  <div class="details">
    <div class="details-top">
      <TitleBox :titlename="'确认转工单'" />
      <div class="closebox" @click="cancel()">
        <p class="el-icon-close"></p>
        <p>关闭</p>
      </div>
    </div>
    <div class="details-center">
      <div class="namebox">
        是否确认转工单?
      </div>

    </div>
    <div class="btnbox">
      <el-button size="mini" class="cancel" type="primary" @click="cancel()">取消</el-button>
      <el-button size="mini" class="affirm" type="primary" @click="diarbtn()">确认</el-button>
    </div>
  </div>
</template>

<script>
import TitleBox from '../visualization/components/title/index.vue'
export default {
  props: ['detailsshow'],
  components: {
    TitleBox
  },
  data () {
    return {
      options: [],
      value: ''
    }
  },
  computed: {},
  watch: {

  },
  methods: {
    diarbtn () {

    },
    cancel () {
      this.$emit('cancel')
    }

  },
  created () { }
}
</script>
<style lang="less" scoped>
.details {
  width: 30%;
  height: 40%;
  position: absolute;
  top: 30%;
  left: 35%;
  background: url('../../assets/homeimg/k2.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  border: 2px solid #59a5ff;
  z-index: 3;
  &-top {
    width: 92%;
    height: 60px;
    padding: 20px;
    margin-left: 4%;
    border-bottom: 1px solid #6ca0c7;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .closebox {
      width: 60px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      font-size: 14px;
      text-align: center;
      font-family: SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
  }
  /deep/.el-input__inner {
    background-color: rgba(83, 118, 161, 0.4);
    color: #bfdafd;
    border: none;
    font-size: 12px;
    border-radius: 0px;
  }

  &-center {
    width: 92%;
    height: 55%;
    border-bottom: 1px solid #6ca0c7;
    margin-left: 4%;
    color: #ffffff;

    .namebox {
      width: 100%;
      height: 60%;
      display: flex;
      align-items: center;
      margin-top: 5%;
      z-index: 5;
      p {
        margin: 0 2% 0 10%;
      }
    }
    .el-select {
      z-index: 30;
    }
  }
  .btnbox {
    width: 100%;
    height: 14%;

    .el-button {
      margin-left: 5%;
      height: 30px;
      margin-top: 5%;
    }
    .cancel {
      border: 1px solid #6da2da;
      background: transparent;
      margin-left: 70%;
    }
  }
}
</style>
